import { render, staticRenderFns } from "./overlapVideo.vue?vue&type=template&id=9e490624&scoped=true&"
import script from "./overlapVideo.vue?vue&type=script&lang=js&"
export * from "./overlapVideo.vue?vue&type=script&lang=js&"
import style0 from "./overlapVideo.vue?vue&type=style&index=0&id=9e490624&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e490624",
  null
  
)

export default component.exports