<!-- 团队风采 -->
<template>
  <div class="teamShow" v-loading="loading">
    <div class="position_btn">
      <el-button class="btn" @click="submit">保&emsp;存</el-button>
    </div>
    <div class="form">
      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="团队风采">
          <span class="warn-text">团队风采展示（1-3张照片）</span>
          <el-upload
            :class="{ disable: imagesMax }"
            :on-success="successFile"
            :before-remove="removeHandler"
            :on-remove="OnremoveHandler"
            :file-list="fileList"
            list-type="picture-card"
            action="/api/hr24_ee/user/v1/files_upload/"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
        <el-form-item label="公司环境">
          <span class="warn-text">公司环境展示（1-3张照片）</span>
          <el-upload
            :class="{ 'disable-c': imagesMaxC }"
            :on-success="successFileC"
            :before-remove="removeHandlerC"
            :on-remove="OnremoveHandlerC"
            list-type="picture-card"
            :file-list="fileListC"
            action="/api/hr24_ee/user/v1/files_upload/"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { setImgApi, setShowApi, setSignApi, setMyHome } from "@/api/me";

import { getCompanyShow } from "@/api/company/company";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      //图片路径
      dialogImageUrl: "",
      //是否显示el-dialog
      dialogVisible: false,
      imagesMax: false,
      imagesMaxC: false,
      totalfileList: [],
      fileListC: [],
      fileList: [],
      // companyfileList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //团队风采  公司环境C
    //图片上传
    successFile(response, file, fileList) {
      console.log(response, file, fileList);
      if (fileList.length >= 3) {
        this.imagesMax = true;
      } else {
        this.imagesMax = false;
      }
      this.fileList = fileList;
      // this.fileList.push({
      //   url: response.file_url,
      //   company_img: response.file_id,
      //   type: 'team',
      // })
      // fileList.filter((item) => {
      //   this.totalfileList.push({ company_img: response.file_id, type: 'team' })
      // })
    },
    successFileC(response, file, fileList) {
      console.log(response, file, fileList);
      if (fileList.length >= 3) {
        this.imagesMaxC = true;
      } else {
        this.imagesMaxC = false;
      }
      this.fileListC = fileList;
      // this.totalfileList.push({ company_img: response.file_id, type: 'index' })
    },
    //删除之前确认弹框
    removeHandler(file, fileList) {
      // if (fileList.length >= 3) {
      //   this.imagesMax = true
      // } else {
      //   this.imagesMax = false
      // }
      return this.$confirm("是否确定删除此图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          return Promise.reject();
        });
    },
    removeHandlerC(file, fileList) {
      return this.$confirm("是否确定删除此图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          return Promise.reject();
        });
    },
    //删除之后
    OnremoveHandler(file, fileList) {
      this.fileList = fileList;
      if (this.fileList.length >= 3) {
        this.imagesMax = true;
      } else {
        this.imagesMax = false;
      }
    },
    OnremoveHandlerC(file, fileList) {
      this.fileListC = fileList;
      if (this.fileListC.length >= 3) {
        this.imagesMaxC = true;
      } else {
        this.imagesMaxC = false;
      }
    },
    /*
    把团队风采  公司环境两组图片放到一个大数组传过去
    */
    submit() {
      console.log(this.fileList);
      let fileList = this.fileList.map((item) => {
        return {
          type: "team",
          company_img:
            item.response != undefined ? item.response.file_id : item.file_id,
        };
        // return {
        //   type: 'team',
        //   company_img:
        //     item.response != undefined ? item.response.file_id : item.file_id,
        // }
      });
      if (fileList.length < 1) {
        this.$message({
          message: "团队风采至少上传一张图片",
          type: "warning",
        });
        return;
      }
      let fileListC = this.fileListC.map((item) => {
        // return {
        //   type: 'index',
        //   company_img:
        //     item.response != undefined ? item.response.file_id : item.file_id,
        // }
        return {
          type: "index",
          company_img:
            item.response != undefined ? item.response.file_id : item.file_id,
        };
      });
      if (fileListC.length < 1) {
        this.$message({
          message: "公司环境至少上传一张图片",
          type: "warning",
        });
        return;
      }
      let newArr = fileList.concat(fileListC);
      console.log(newArr);

      setImgApi(newArr).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
      console.log("company_ca", this.$store.state.companyInfo.company_info);
      setShowApi(this.$store.state.companyInfo.company_info).then((res) => {
        if (res.code === 0) {
        } else if (res.code === 110) {
          this.$confirm(res.msg, "提示", {
            confirmButtonText: "确定",
            showClose: false,
            showCancelButton: false,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
      setSignApi(this.$store.state.companyInfo.company_fengyun).then((res) => {
        if (res.code === 0) {
          // this.isForm = false
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
      setMyHome().then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.session_logo = sessionStorage.setItem(
            "logo",
            res.data.company_logo
          );
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //得到数据 判断 图片数量 是否显示那个el 保存 按钮  [1,3]
    getCompanyShow("img").then((res) => {
      console.log(res);
      if (res.code === 0) {
        this.loading = false;
        this.totalfileList = res.data.img_data;
        this.totalfileList.filter((item) => {
          if (item.type === "index") {
            this.fileListC.push({
              url: item.img_data,
              file_id: item.company_img,
            });
            if (this.fileListC.length >= 3) {
              this.imagesMaxC = true;
            } else {
              this.imagesMaxC = false;
            }
          } else if (item.type === "team") {
            this.fileList.push({
              url: item.img_data,
              file_id: item.company_img,
            });
            if (this.fileList.length >= 3) {
              this.imagesMax = true;
            } else {
              this.imagesMax = false;
            }
          }
        });
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log("进来了2");
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.disable /deep/ .el-upload--picture-card {
  display: none;
}
.disable-c /deep/ .el-upload--picture-card {
  display: none;
}
.warn-text {
  font-size: 12px;
  color: red;
}
div /deep/ .el-form-item__label::before {
  content: "*";
  /* position: absolute; */
  padding-right: 6px;
  color: red;
}
.teamShow {
  position: relative;
}

</style>