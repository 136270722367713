<!-- 生成海报 -->
<template>
  <div class="wrap">
    <div class="smk-select">
      <span>选择分享职位：</span>
      <el-select v-model="value" placeholder="请选择" @change="jdChange">
        <el-option
          v-for="item in show"
          :key="item.id"
          :label="item.jd_title"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
    <div style="float: right">
      <p class="mingcheng">
        24猎精英版小程序名称：<span>24猎丨精英版</span>
        <br />（可在公众号文章中关联小程序进行复制）
      </p>
      <el-button type="warning" @click="is_shi = true">使用说明</el-button>
      <el-dialog
        title=""
        :visible.sync="is_shi"
        width="30%"
        append-to-body
        :before-close="
          () => {
            is_shi = false;
          }
        "
      >
        <div class="">
          <h3>使用说明（点击放大）</h3>
          <div class="img">
            <el-image
              z-index="100000"
              style="width: 50%"
              src="https://oss.hr24.com.cn/public/9b3b17aafc534f5db50ff18825733860.jpg?BUCKET=jpg-bucket"
              :preview-src-list="[
                'https://oss.hr24.com.cn/public/9b3b17aafc534f5db50ff18825733860.jpg?BUCKET=jpg-bucket',
              ]"
            >
            </el-image>
            <el-image
              z-index="100000"
              style="width: 50%"
              src="https://oss.hr24.com.cn/public/1278b4ee4501439587237b85c8ea0c73.jpg?BUCKET=jpg-bucket"
              :preview-src-list="[
                'https://oss.hr24.com.cn/public/1278b4ee4501439587237b85c8ea0c73.jpg?BUCKET=jpg-bucket',
              ]"
            >
            </el-image>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="conent">
      <div class="img_left">
        <div class="img_con" v-if="photoData">
          <div
            v-for="(item, index) in photoData"
            :key="index"
            :class="indexImg === index ? 'indexImg' : ''"
            @click="indexImgClick(index)"
          >
            <img :src="item.thumbnail" alt="" />
            <div class="BGImg" v-if="indexImg === index">
              <div class="btnImg">
                <div class="btnImg_left" @click="openDialog">生成海报</div>
                <div
                  class="btnImg_left1"
                  @click="copyLink"
                  data-clipboard-action="copy"
                  :data-clipboard-text="link_jd"
                >
                  复制链接
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="canvas_right">
        <div class="bao">
          <div class="canvas" ref="newImg">
            <div class="title">&nbsp;</div>
            <div class="jie" v-if="company_data.company_introduce">
              <p class="tit">关于我们</p>
              <p class="companyName">
                <span v-for="(item, index) in company_name" :key="index">
                  {{ item }}
                  <template> <br /></template>
                </span>
              </p>
              <p class="span">
                <!-- <span
                v-if="company_data.company_industry_data.second_industry_name"
                >{{
                  company_data.company_industry_data.second_industry_name
                }}</span
              >~ -->
                <span
                  v-if="
                    company_data.company_people_max ==
                    company_data.company_people_min
                  "
                >
                  {{ company_data.company_people_max }} 人
                </span>
                <span v-else>
                  {{ company_data.company_people_min }} -
                  {{ company_data.company_people_max }} 人
                </span>
              </p>
              <p class="diqu">{{ company_data.company_map_detail }}</p>
              <p
                class="company_jieshao"
                v-for="(item, i) in company_data.company_introduce.split('\n')"
                :key="i"
              >
                {{ item }}
              </p>

              <img :src="company_img" alt="" />
            </div>
            <div class="jie1">
              <img :src="jie1Img" alt="" />
            </div>
            <div class="jie2" v-if="odd_job == 0">
              <p class="tit">公司福利</p>
              <p class="company_fuli">
                <span v-for="(item, ii) in jd_data.jd_tags_name" :key="ii">{{
                  item
                }}</span>
              </p>
            </div>
            <div class="jie2" v-else>
              <p class="tit">技能要求</p>
              <p class="company_fuli">
                <span>{{ jd_data.jd_skill | jd_skillfif }}</span>
              </p>
            </div>
            <div class="jie1">
              <img :src="jie1Img" alt="" />
            </div>
            <div class="jie2">
              <p class="tit">招聘职位</p>
              <div class="company_fuli">
                <p>职位名称：{{ jd_data.jd_title }}</p>
                <template v-if="odd_job == 0">
                  <p>工作经验：{{ jd_data.jd_exp_name }}</p>
                  <p>学历要求：{{ jd_data.jd_edu_name }}</p>
                  <p v-if="jd_data.jd_salary_min == jd_data.jd_salary_max">
                  薪资范围：{{ jd_data.jd_salary_max }} 元
                </p>
                <p v-else>
                  薪资范围：{{ jd_data.jd_salary_min }}-{{
                    jd_data.jd_salary_max
                  }}
                  元
                </p>
                </template>
                <template v-else>
                  <p>
                    年龄要求：<template
                      v-if="jd_data.jd_age_min != jd_data.jd_age_max"
                      >{{ jd_data.jd_age_min }} -
                      {{ jd_data.jd_age_max }}岁</template
                    ><template v-else>{{ jd_data.jd_age_min }}岁</template>
                  </p>
                  <p>
                    性别要求：<template v-if="jd_data.jd_gender == 0">性别不限</template
                    ><template v-if="jd_data.jd_gender == 1">男</template
                    ><template v-if="jd_data.jd_gender == 2">女</template>
                  </p>
                  <p>岗位薪资：{{ jd_data.jd_salary_max }}元/{{ jd_data.jd_salary_type }}</p>
                </template>

                
                <p>职位地点：{{ jd_data.place_name }}</p>
                <div>
                  职位描述：
                  <p
                    v-for="(item, index) in jd_data.jd_desc.split('\n')"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>
              </div>
            </div>
            <div class="fotter">
              <img :src="fotterImg" alt="" />
              <div class="code">
                <img :src="ecode" alt="" />
              </div>
            </div>
            <div class="logo">
              <img :src="company_data.company_logo" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="isCanvas"
      width="450px"
      :before-close="
        () => {
          isCanvas = false;
        }
      "
    >
      <div
        class="fuceng"
        v-loading="loading"
        element-loading-text="图片生成中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.1)"
      ></div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState, mapActions } from "vuex";

import { official_account_qr } from "@/api/tool";
import { get_video_photo } from "@/api/promotion";
import { getCompanyShow } from "@/api/company/company";
import Vue from "vue";
import VueLazyload from "vue-lazyload";
import html2canvas from "html2canvas";
import TeamShow from "../../components/setShow/teamShow.vue";
Vue.use(VueLazyload, {
  preLoad: 0.8,
  // error: 'dist/error.png',
  loading: require("./../../assets/images/test/isdown.gif"),
  attempt: 1,
});
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    TeamShow;
    //这里存放数据
    return {
      //海报数据
      loading: false,
      dialogVisible: false,
      value: "",
      ecode: "",
      jd_data: { jd_desc: "" },
      telephone_hotline: "",
      company_data: "",
      company_img: "",
      show: [],
      link_jd: "cas",
      is_shi: false,
      photoData: [],
      indexImg: "",
      jie1Img: "",
      fotterImg: "",
      isCanvas: false,
      company_name: [],
      // 是否为零工账号
      odd_job: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  filters: {
    jd_skillfif(val) {
      if (val) {
        return val.split(",").join("     ");
      }
    },
  },
  methods: {
    indexImgClick(index) {
      console.log("走乐乐乐乐", index);
      if (!this.value) {
        this.$message.warning("请选择职位！");
        return false;
      }
      this.$nextTick(() => {
        this.indexImg = index;
        let obj = this.photoData[index].details;
        let head = obj.head; //头部图片
        let pc_conent = obj.wx_conent; //中间嵌套图片
        let contact = obj.contact; //内容链接节点
        let bottom = obj.bottom; //底部图片

        console.log("元素", document.getElementsByClassName("title")[0]);
        document
          .getElementsByClassName("title")[0]
          .setAttribute(
            "style",
            `background: url(${head}) no-repeat;background-size: 100% 100%;`
          );
        document
          .getElementsByClassName("jie")[0]
          .setAttribute(
            "style",
            `background: url(${pc_conent}) no-repeat;background-size: 100% 100%;`
          );
        document
          .getElementsByClassName("jie2")[0]
          .setAttribute(
            "style",
            `background: url(${pc_conent}) no-repeat;background-size: 100% 100%;`
          );
        document
          .getElementsByClassName("jie2")[1]
          .setAttribute(
            "style",
            `background: url(${pc_conent}) no-repeat;background-size: 100% 100%;`
          );
        document
          .getElementsByClassName("jie2")[1]
          .setAttribute(
            "style",
            `background: url(${pc_conent}) no-repeat;background-size: 100% 100%;`
          );
        this.jie1Img = contact;
        this.fotterImg = bottom;
      });
    },

    //复制二维码连接
    copyLink() {
      if (!this.company_data.company_logo) {
        this.$message.warning("请完善公司详情！");
        return false;
      }
      if (!this.value) {
        this.$message.warning("请选择职位！");
        return false;
      }
    },
    jdChange() {
      console.log(this.value);
      //   this.loading = true;
      this.show.forEach((element) => {
        if (this.value == element.id) {
          this.jd_data = element;
          console.log(this.jd_data.jd_skill);
          // this.jd_data.jd_skill = this.jd_data.jd_skill.split(",");
          console.log(this.jd_data, "职位");
          this.link_jd = `packageA/pages/job_details/job_details?is_online=0&position_live=true&id=${this.jd_data.id}&official_account=1`;
        }
      });
      official_account_qr({ jd_id: this.value }).then((res) => {
        if (res.code == 0) {
          console.log(res, "职位");
          this.ecode = res.data.qr;
        }
      });
    },
    get_video_photo() {
      get_video_photo().then((res) => {
        if (!res.code) {
          this.photoData = res.data;

          console.log("海报", res.data);
        }
      });
    },
    getCompanyShow() {
      getCompanyShow("all").then((res) => {
        console.log(res, "公司");
        if (!res.code) {
          this.company_data = res.data;
          if (sessionStorage.getItem("odd_job") == 1) {
            this.show = res.data.jd_data.filter((item) => {
              return item.odd_status == 2;
            });
          } else {
            this.show = res.data.jd_data;
          }
          let index = 0;
          this.company_data.img_data.forEach((ele) => {
            if (index == 0 && ele.type == "index") {
              this.company_img = ele.img_data;
              index++;
            }
          });
          let text = "";
          let company_name = res.data.company_name.split("");
          let index_1 = 0;
          company_name.forEach((item, idnex) => {
            console.log(item, idnex % 14);
            if (idnex % 14 == 0 && index_1 != 0) {
              index_1++;
              text += item + ",";
            } else {
              text += item;
              index_1++;
            }
          });
          this.company_name = text.split(",");
          console.log(this.company_name);
          this.get_video_photo();
        }
      });
    },
    //点击生成
    openDialog() {
      if (!this.company_data.company_logo) {
        this.$message.warning("请完善公司详情！");
        return false;
      }
      this.$nextTick(() => {
        this.setImage();
      });
    },
    setImage() {
      let that = this;
      var canvas2 = document.createElement("canvas");
      let _canvas = this.$refs.newImg;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动轴滚动的长度
      html2canvas(_canvas, {
        allowTaint: true,
        scrollY: -scrollTop,
        scrollX: 0,
      }).then(function (canvas) {
        console.log(canvas);
        that.isCanvas = true;
        that.$nextTick(() => {
          that.loading = false;
          let div = document.getElementsByClassName("fuceng")[0];
          if (div.childNodes.length > 0) {
            div.removeChild(div.childNodes[0]);
          }
          canvas.style.width = "375px";
          canvas.style.height = "auto";
          document.getElementsByClassName("fuceng")[0].appendChild(canvas);
        });
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.odd_job = sessionStorage.getItem("odd_job");
    // 复制
    let _this = this;
    let clipboard = new this.$clipboard(".btnImg_left1");
    clipboard.on("success", function () {
      _this.$message({
        message: "复制成功",
        type: "success",
      });
    });
    clipboard.on("error", function () {
      _this.$message.success("复制失败");
    });
    this.getCompanyShow();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
  min-height: 80vh;
  // height:calc(100vh - 91px);
  //background:red
}
.smk-select {
  float: left;
  margin-bottom: 20px;
  span {
    color: $selfColor;
  }
}
.conent {
  // width: 100%;
  clear: both;
  overflow: hidden;
  background: #fff;
  padding: 20px;
  .img_left {
    width: 100%;
    float: left;

    .img_con {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      cursor: pointer;
      margin-top: 20px;
      // justify-content: space-between;
      & > div {
        width: 24%;
        border: 2px solid rgb(255, 255, 255);
        text-align: center;
        padding: 4px 0;
        position: relative;
        img {
          width: 92%;
        }
        &:hover {
          border: 2px solid rgb(255, 115, 0);
        }
        & > .BGImg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          background: rgba(0, 0, 0, 0.5);
          // display: none;
          .btnImg {
            width: 80%;
            margin: auto;
            height: 106px;
            // clear: both;
            text-align: center;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -53px;
            margin-left: -40%;
            // margin-top: 20px;
            .btnImg_left,
            .btnImg_left1 {
              padding: 8px 20px;
              background: #fa9e25;
              color: rgb(236, 235, 235);
              border-radius: 6px;
              margin: 12px 0;
              // font-weight: bold;
              font-size: 14px;
              cursor: pointer;
              &:hover {
                background: #e98707;
                color: rgb(255, 255, 255);
              }
            }
          }
          &:hover {
            display: block;
          }
        }
      }
      .indexImg {
        border: 2px solid rgb(255, 115, 0);
      }
    }
  }
  .canvas_right {
    width: 0px;
    float: left;
    min-height: 618px;
    position: relative;
    overflow: hidden;
  }
}
.bao {
  width: 375px;
  margin: auto;
  overflow: hidden;
}
.canvas {
  width: 580px;
  margin: auto;
  color: #000000;
  // font-family:;
  font-size: 21px;
  position: relative;

  .title {
    width: 100%;
    // background-size: 100% 100%;
    // height: 150px;
    padding-top: 300px;

    p {
      margin: 0;
      margin-left: 75px;
    }
    .tit {
      margin-left: 0;
      text-align: center;
      margin-bottom: 5px;
      font-size: 24px;
    }
  }
  .jie {
    width: 100%;
    margin-top: -1px;
    p {
      margin: 0;
      padding: 0 75px;
    }
    .tit {
      text-align: center;
      margin: -5px 0 10px 0;
    }
    .companyName {
      width: 80%;
    }
    .company_jieshao {
      width: calc(100% - 150px);
      padding: 0 75px;
      margin-bottom: 8px;
    }
    .company_fuli {
      width: calc(100% - 150px);
      padding: 0 75px;

      overflow: hidden;
      span {
        padding: 6px 12px;
        float: left;
      }
    }
    img {
      display: block;
      width: calc(100% - 150px);
      margin: auto;
    }
  }
  .jie1 {
    margin-top: -1px;
    img {
      width: 100%;
    }
  }
  .jie2 {
    width: 100%;
    margin-top: -5px;
    // padding-top: 100px;
    p {
      margin: 0;
      padding: 0 75px;
    }
    .tit {
      font-size: 24px;
      text-align: center;
      margin: 0 0 10px 0;
    }
    .company_jieshao {
      width: calc(100% - 150px);
      padding: 0 75px;
      margin-bottom: 18px;
    }
    .company_fuli {
      width: calc(100% - 150px);
      padding: 0 75px;

      overflow: hidden;
      span {
        margin: 3px 9px 3px 0;
        float: left;
      }
      p {
        padding: 0 0;
      }
    }
    img {
      display: block;
      width: calc(100% - 100px);
      margin: auto;
    }
  }
  .fotter {
    position: relative;
    margin-top: -1px;
    img {
      width: 100%;
    }
    .code {
      position: absolute;
      top: 98px;
      right: 75px;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    }
  }
  .logo {
    position: absolute;
    top: 310px;
    right: 75px;
    img {
      width: 83px;
      height: 83px;
      border-radius: 50%;
    }
  }
}
.fuceng {
  width: 375px;
  margin: auto;
  height: 100%;
  background: #fff;
  // opacity: 0.5;

  // display: none;
}
.mingcheng {
  margin: 0;
  // line-height: 9px;
  float: left;
  margin-right: 20px;
  color: #ccc;
  span {
    color: #e98707;
  }
}
</style>
